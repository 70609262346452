<script setup>
import {
  ref,
  reactive,
  onMounted,
  onBeforeUnmount,
  useAsyncData,
  useRuntimeConfig,
} from '#imports';
import { loadStripe } from '@stripe/stripe-js';
import { nanoid } from 'nanoid';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import { config } from '~/services/toasts/config';

const props = defineProps({
  email: { type: String, required: true },
  amount: { type: Object, required: true, default: () => ({}) },
  currency: { type: Object, required: true },
  isSubscription: { type: Boolean, required: true },
  isCustomAmount: { type: Boolean, required: true },
  label: { type: String, default: '' },
  isEnglish: { type: Boolean },
});

const cardElement = ref();

const emit = defineEmits(['loading', 'loaded', 'successfulPayment']);

const runtimeConfig = useRuntimeConfig();

const stripeFieldsStyle = {
  base: {
    iconColor: '#6c2179',
    color: '#6c2179',
    fontWeight: '500',
    fontFamily: 'Montserrat, Arial, sans-serif',
    fontSize: '22px',
    backgroundColor: '#F5EAF3',
    '::placeholder': {
      color: '#938C92',
    },
  },
  invalid: {
    iconColor: '#eb1c26',
    color: '#eb1c26',
  },
};

const errorMessage = ref();
const stripeProperties = reactive({
  stripeInstance: undefined,
  elements: undefined,
  cardElement: undefined,
});

function createStripeElement() {
  stripeProperties.elements = stripeProperties.stripeInstance.elements();
  stripeProperties.cardElement = stripeProperties.elements.create('card', {
    style: stripeFieldsStyle,
    hidePostalCode: true,
  });
  stripeProperties.cardElement.mount(cardElement.value);
}

function handleError(error) {
  const { message } = error.value.data;
  toast.error(message, config);
  throw new Error(message);
}

async function createPaymentIntent(token) {
  const requestId = nanoid();

  const { data, error } = await useAsyncData(requestId, () =>
    $fetch('/api/v1/create-payment-intent', {
      method: 'POST',
      headers: { 'Cf-Turnstile-Token': token },
      body: {
        amount: props.amount.amountValue,
        email: props.email,
        currency: props.currency.name,
      },
    })
  );

  if (error.value) {
    handleError(error);
  }

  const { clientSecret } = data.value;
  return clientSecret;
}

async function createCustomer() {
  const requestId = nanoid();
  const { data, error } = await useAsyncData(requestId, () =>
    $fetch('/api/v1/create-customer', {
      method: 'POST',
      body: {
        email: props.email,
      },
    })
  );

  if (error.value) {
    handleError(error);
  }

  const { customerId } = data.value;
  return customerId;
}

async function createProduct() {
  const requestId = nanoid();
  const { data, error } = await useAsyncData(requestId, () =>
    $fetch('/api/v1/create-product', {
      method: 'POST',
      body: {
        amount: props.amount.amountValue,
        currency: props.currency.name,
      },
    })
  );

  if (error.value) {
    handleError(error);
  }

  const { productId } = data.value;
  return productId;
}

async function createPrice() {
  const productId = await createProduct();
  const requestId = nanoid();

  const { data, error } = await useAsyncData(requestId, () =>
    $fetch('/api/v1/create-price', {
      method: 'POST',
      body: {
        productId,
        amount: props.amount.amountValue,
        currency: props.currency.name,
      },
    })
  );

  if (error.value) {
    handleError(error);
  }

  const { priceId } = data.value;
  return priceId;
}

async function createSubscription(token) {
  const customerId = await createCustomer();

  const priceId = props.isCustomAmount
    ? await createPrice()
    : props.amount.priceId;

  const requestId = nanoid();

  const { data, error } = await useAsyncData(requestId, () =>
    $fetch('/api/v1/create-subscription', {
      method: 'POST',
      headers: { 'Cf-Turnstile-Token': token },
      body: {
        priceId,
        customerId,
      },
    })
  );

  if (error.value) {
    handleError(error);
  }

  const { clientSecret } = data.value;
  return clientSecret;
}

function handleCardErrorMessage({ error }) {
  errorMessage.value = error ? error.message : '';
}

async function initialize() {
  stripeProperties.stripeInstance = await loadStripe(
    runtimeConfig.public.stripePublishableKey,
    {
      locale: props.isEnglish ? 'en' : 'ru',
    }
  );

  emit('loaded');

  createStripeElement();

  stripeProperties.cardElement.addEventListener(
    'change',
    handleCardErrorMessage
  );
}

async function createPayment(clientSecret) {
  const result = await stripeProperties.stripeInstance.confirmCardPayment(
    clientSecret,
    {
      payment_method: {
        card: stripeProperties.cardElement,
        billing_details: {
          email: props.email,
        },
      },
    }
  );

  if (result.error) {
    const { message } = result.error;
    errorMessage.value = message;
    toast.error(message, config);
    throw new Error(message);
  }

  if (result.paymentIntent?.status === 'succeeded') {
    emit('successfulPayment');
  }
}

async function submit(token) {
  emit('loading', true);

  try {
    const clientSecret = await (props.isSubscription
      ? createSubscription(token)
      : createPaymentIntent(token));

    await createPayment(clientSecret);
  } catch {
  } finally {
    emit('loading', false);
  }
}

onMounted(() => initialize());

onBeforeUnmount(() => {
  stripeProperties.cardElement?.removeEventListener(
    'change',
    handleCardErrorMessage
  );
});

defineExpose({ submit });
</script>

<template>
  <span class="stripe-card">
    <span v-if="label" class="stripe-card__label">{{ label }} </span>
    <span ref="cardElement" class="stripe-card__input"></span>
    <span class="stripe-card__error-message" role="alert">{{
      errorMessage
    }}</span>
  </span>
</template>

<style lang="postcss" scoped>
.stripe-card {
  --input-width: 390px;

  position: relative;

  &__label {
    display: block;
    margin-bottom: 6px;
    position: relative;
    width: 100%;
    font-size: 18px;
    line-height: 22px;
    font-weight: 500;
    color: #938c92;
  }

  &__input {
    display: block;
    width: var(--input-width);
    height: var(--control-height);
    padding: 11px 14px;
    border: 0;
    border-radius: 16px;
    font-weight: 500;
    font-size: 22px;
    line-height: 32px;
    color: var(--accent-color);
    background-color: #f5eaf3;
  }

  &__error-message {
    position: absolute;
    width: var(--input-width);
    font-size: 12px;
    color: var(--error-color);
  }

  @media (--small-vp) {
    --input-width: 100%;

    &__label {
      font-size: 12px;
      line-height: 15px;
      margin-bottom: 4px;
    }

    &__error-message {
      width: max-content;
    }
  }
}

.StripeElement--webkit-autofill {
  background-color: transparent;
}
</style>
