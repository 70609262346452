const copyByFakeElement = (value) => {
  const fakeElement = document.createElement('input');
  fakeElement.setAttribute('value', value);
  fakeElement.style.position = 'fixed';
  fakeElement.style.left = '-9999px';
  document.body.append(fakeElement);
  fakeElement.select();
  document.execCommand('copy');
  fakeElement.remove();
};

const hasNavigatorPermission = async () => {
  if (!navigator.permissions) return false;

  const { state } = await navigator.permissions.query({
    name: 'clipboard-write',
  });
  return state !== 'denied';
};

export const copyToClipboard = async (value) => {
  try {
    if (navigator.clipboard && hasNavigatorPermission()) {
      await navigator.clipboard.writeText(value);
    } else {
      copyByFakeElement(value);
    }
    return true;
  } catch {
    return false;
  }
};
