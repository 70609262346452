<script setup>
import { computed } from '#imports';

const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: undefined,
  },
  type: {
    type: String,
    default: 'text',
    // Only allow types that essentially just render text boxes.
    validator(value) {
      return [
        'email',
        'number',
        'password',
        'search',
        'tel',
        'text',
        'url',
      ].includes(value);
    },
  },
  label: {
    type: String,
    default: '',
  },
  prefix: {
    type: String,
    default: '',
  },
  isBig: {
    type: Boolean,
    default: false,
  },
  isError: {
    type: Boolean,
    default: false,
  },
  errorMessage: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['update:modelValue']);

const value = computed({
  get() {
    return props.modelValue;
  },
  set(newValue) {
    emit('update:modelValue', newValue);
  },
});
</script>

<template>
  <label
    class="text-input"
    :class="{
      'text-input--big': isBig,
      'text-input--with-prefix': prefix,
      'text-input--error': isError,
    }"
  >
    <span v-if="label" class="text-input__label-text">{{ label }}</span>
    <span class="text-input__control-wrapper">
      <span v-if="prefix" class="text-input__prefix">{{ prefix }}</span>
      <input
        v-model="value"
        :type="type"
        class="text-input__control"
        v-bind="$attrs"
      />
    </span>
    <span v-if="errorMessage" class="text-input__error-message">{{
      errorMessage
    }}</span>
  </label>
</template>

<style lang="postcss" scoped>
.text-input {
  --prefix-width: 40px;

  position: relative;

  input[type='search']::-webkit-textfield-decoration-container {
    width: calc(100% - 40px);
  }

  input[type='search']::-webkit-search-cancel-button {
    -webkit-appearance: none;
    cursor: pointer;
    height: 12px;
    width: 12px;
    padding: 20px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='none'%3E%3Cpath d='M12 0.746398C12 0.546068 11.922 0.357648 11.7803 0.216126C11.4968 -0.0673219 11.0032 -0.0673219 10.7197 0.216126L6 4.93585L1.28027 0.216126C0.996824 -0.0673219 0.503172 -0.0673219 0.219724 0.216126C0.0780155 0.357648 0 0.546072 0 0.746398C0 0.946705 0.0780155 1.13512 0.219728 1.27667L4.93946 5.9964L0.219728 10.7161C0.0780155 10.8576 0 11.0461 0 11.2464C0 11.4467 0.0780155 11.6351 0.219728 11.7767C0.503175 12.0601 0.996828 12.0601 1.28028 11.7767L6 7.05695L10.7197 11.7767C10.8615 11.9184 11.0497 11.9964 11.25 11.9964C11.4503 11.9964 11.6386 11.9184 11.7803 11.7767C11.922 11.6351 12 11.4467 12 11.2464C12 11.0461 11.922 10.8576 11.7803 10.7161L7.06055 5.9964L11.7803 1.27667C11.922 1.13513 12 0.946705 12 0.746398Z' fill='black'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    right: 0;
    top: 0;
  }

  &__label-text {
    display: block;
    margin-bottom: 6px;
    position: relative;
    width: 100%;
    font-size: 18px;
    line-height: 22px;
    font-weight: 500;
    color: #938c92;
  }

  &__control-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: var(--control-height);
  }

  &__control {
    width: 100%;
    max-width: 100%;
    font-family: var(--main-font-family);
    height: var(--control-height);
    padding: 14px;
    border: 0;
    font-weight: 500;
    font-size: var(--body-font-size);
    line-height: 32px;
    border-radius: 16px;
    color: var(--accent-color);
    background-color: #f5eaf3;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: rgba(var(--black-color--rgb), 40%);
      font-family: var(--main-font-family);
    }

    &[data-com-onepassword-filled='light'] {
      background-color: #f5eaf3 !important;
      box-shadow: 0 0 0 30px #f5eaf3 inset !important;
    }
  }

  &__prefix {
    width: var(--prefix-width);
    height: var(--control-height);
    padding: 8px 4px 8px 14px;
    text-align: center;
    font-size: 22px;
    line-height: 32px;
    vertical-align: baseline;
    border-radius: 16px 0 0 16px;
    color: var(--accent-color);
    background-color: #f5eaf3;
  }

  &__error-message {
    position: absolute;
    font-size: 12px;
    color: var(--error-color);
    width: max-content;
  }

  &--with-prefix {
    .text-input__control {
      max-width: calc(100% - var(--prefix-width));
      padding: 8px 14px 8px 4px;
      border-radius: 0 16px 16px 0;
    }
  }

  &--error {
    & input {
      color: var(--error-color);
    }
  }

  @media (--small-vp) {
    --prefix-width: 26px;

    &__label-text {
      font-size: 12px;
      line-height: 15px;
      margin-bottom: 4px;
    }

    &__control {
      border-radius: 12px;
    }

    &__prefix {
      border-radius: 12px 0 0 12px;
      padding: 8px 3px 8px 8px;
    }

    &--big {
      .text-input__control {
        font-size: 22px;
        line-height: 27px;
      }

      .text-input__prefix {
        line-height: 34px;
      }
    }

    &--with-prefix {
      .text-input__control {
        padding: 8px 15px 8px 3px;
        border-radius: 0 12px 12px 0;
      }
    }

    &--error {
      .text-input__control-wrapper {
        border-radius: 12px;
      }
    }
  }
}
</style>
