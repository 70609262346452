import { useTrackEvent } from '#imports';

export function useEventTracking(eventType, name, placement) {
  useTrackEvent(eventType, {
    props: {
      name,
      placement,
    },
  });
}
