<script>
import Popper from 'vue3-popper';
import './theme.css';

const placements = new Set([
  'top-start',
  'top',
  'top-end',
  'bottom-start',
  'bottom',
  'bottom-end',
  'left-start',
  'left',
  'left-end',
  'right-start',
  'right',
  'right-end',
  'auto',
]);

export default {
  components: { Popper },
  props: {
    placement: {
      type: String,
      default: 'auto',
      validator: (value) => placements.has(value),
    },
    hasArrow: { type: Boolean, default: false },
    theme: {
      type: String,
      default: 'default',
      validator: (theme) => ['default', 'main-burger-menu'].includes(theme),
    },
  },
};
</script>

<template>
  <ClientOnly>
    <Popper
      :class="theme"
      :placement="placement"
      :prevent-overflow="{ enabled: false }"
      :visible-arrow="hasArrow"
      :append-to-body="true"
      boundaries-selector="body"
      hover
    >
      <slot name="reference" />
      <template #content="{ close }">
        <slot name="content" :close="close" />
      </template>
    </Popper>
  </ClientOnly>
</template>

<style>
.popper .post-content {
  position: unset;
}
</style>
