<template>
  <div class="modal-overlay" @click.self="$emit('close', false)">
    <div class="mobile-modal" :class="{ 'mobile-modal--dark': isDark }">
      <div class="mobile-modal__content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VMobileModal',
  props: {
    isDark: { type: Boolean, default: false },
  },
};
</script>

<style lang="postcss" scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: var(--modal-z-index);
}

.mobile-modal {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 16px 12px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  background-color: var(--white-color);
  box-shadow: 0 0 24px rgba(0, 0, 0, 10%);

  &--dark {
    background-color: var(--accent-color);
  }

  &__content {
    max-height: 70vh;
    overflow: auto;
  }

  @media (max-width: 499px) {
    width: 100%;
  }
}
</style>
