<script setup>
import { ref, useTrackEvent } from '#imports';

const isOpenModal = ref(false);
function trackEvent(eventType, name) {
  useTrackEvent(eventType, {
    props: {
      name,
      placement: window.location.pathname,
    },
  });
}

const handleClick = (name) => {
  trackEvent('click', name);
};
</script>

<template>
  <span class="crypto-wallets">
    <span
      class="crypto-wallets__tooltip"
      @click="handleClick('Click on Crypto Wallets')"
    >
      <BaseTooltip>
        <template #reference>
          <slot name="reference" />
        </template>
        <template #content>
          <DonationBlockCryptoWalletsList />
        </template>
      </BaseTooltip>
    </span>
    <span
      class="crypto-wallets__mobile-link"
      @click="isOpenModal = !isOpenModal"
    >
      <slot name="reference" />
    </span>
    <Teleport to="body">
      <div v-if="isOpenModal" class="modal">
        <BaseMobileModal @close="isOpenModal = false">
          <DonationBlockCryptoWalletsList />
        </BaseMobileModal>
      </div>
    </Teleport>
  </span>
</template>

<style lang="postcss" scoped>
.crypto-wallets {
  display: flex;
  flex-direction: column;
  gap: 12px;
  font-size: 16px;
  line-height: 24px;
  word-wrap: break-word;

  &__name {
    font-weight: 700;
  }

  &__key {
    font-size: 16px;
    line-height: 24px;
  }

  &__copy-icon {
    display: inline-block;
    margin-left: 4px;
    width: 14px;
    height: 14px;
  }

  &__mobile-link {
    display: none;
  }

  @media (--small-vp) {
    &__tooltip {
      display: none;
    }

    &__mobile-link {
      display: block;
    }
  }
}
</style>
