<script setup>
import { computed, ref, watch } from '#imports';
import stripeLogo from '~/assets/images/stripe-logo.svg';
import PaymentSuccess from '~/components/payment-form/payment-success.vue';
import { useEventTracking } from '@/utils/use-event-tracking';
import { useAmountOptions } from './use-amount-options';
import { useValidateForm } from './use-validate-form';
import {
  currencies,
  defaultData,
  offerData,
  translatedData,
} from './constants';

const props = defineProps({
  isEnglish: {
    type: Boolean,
  },
});

const data = props.isEnglish ? translatedData : defaultData;

const subscriptionOptions = [
  { value: true, label: props.isEnglish ? 'Monthly' : 'Ежемесячно' },
  { value: false, label: props.isEnglish ? 'One time' : 'Разово' },
];
const foreignCurrencies = Object.values(currencies).filter(
  ({ name }) => name !== 'rub'
);

const isSubscription = ref(subscriptionOptions[0].value);

const stripeCard = ref();
const email = ref('');
const currency = ref(currencies.usd);
const currentAmountOptions = useAmountOptions({
  currency,
  isSubscription,
});
const standardAmount = ref(currentAmountOptions.value[0]);
const customAmount = ref();
const stripeIsLoaded = ref(false);
const loading = ref(false);
const showSuccessfulMessage = ref(false);
const turnstile = ref();
const token = ref('');

const isCustomAmount = computed(() => Boolean(customAmount.value));
const buttonCaption = computed(() =>
  isSubscription.value
    ? data.labels.buttonCaptionMonthly
    : data.labels.buttonCaptionOneTime
);

function normalizeAmount(value) {
  return value * 100;
}

const resultAmount = computed(() =>
  isCustomAmount.value
    ? { amountValue: normalizeAmount(customAmount.value) }
    : standardAmount.value
);

const prefix = computed(() => currency.value.label);

const { emailErrorMessage, customAmountErrorMessage } = useValidateForm({
  email,
  customAmount,
  prefix,
  isEnglish: props.isEnglish,
});

const isButtonDisabled = computed(
  () =>
    !email.value ||
    emailErrorMessage.value ||
    !resultAmount.value.amountValue ||
    customAmountErrorMessage.value ||
    loading.value
);

function makeDefaultStandardAmount() {
  [standardAmount.value] = currentAmountOptions.value;
}

function resetStandardAmount() {
  standardAmount.value = {};
}

function resetCustomAmount() {
  customAmount.value = undefined;
}

watch(isSubscription, () => {
  if (!customAmount.value) makeDefaultStandardAmount();
});

function handleSubmit() {
  useEventTracking('Payment Form', 'Attempt to submit payment form');

  stripeCard.value.submit(token.value);
  turnstile.value?.reset();
}

watch(showSuccessfulMessage, () => {
  if (showSuccessfulMessage.value === true) {
    setTimeout(() => {
      useEventTracking('Payment Form - Step 6 - Donation successful');
      showSuccessfulMessage.value = false;
      loading.value = false;
    }, 5000);
  } else {
    useEventTracking('Payment Form - Step 6 - Donation failed');
  }
});
</script>

<template>
  <span class="payment-form-wrap">
    <form v-show="stripeIsLoaded" class="payment-form">
      <Transition mode="out-in" name="fade">
        <PaymentSuccess
          v-if="showSuccessfulMessage"
          :success-message="data.successMessage"
        />
        <div v-else class="payment-form__inner">
          <fieldset class="payment-form__fields">
            <div class="payment-form__field-wrap">
              <BaseRadioGroup
                v-model="isSubscription"
                :label="data.labels.subscription"
                :options="subscriptionOptions"
                name="subscription"
                @click.once="
                  useEventTracking(
                    'Payment Form - Step 1 - Subscription chosen'
                  )
                "
              />
            </div>

            <div class="payment-form__field-wrap">
              <BaseRadioGroup
                v-model="currency"
                :label="data.labels.currency"
                :options="foreignCurrencies"
                name="currency"
                @click.once="
                  useEventTracking('Payment Form - Step 2 - Currency chosen')
                "
              />
            </div>
          </fieldset>

          <fieldset class="payment-form__fields">
            <div class="payment-form__field-wrap">
              <BaseRadioGroup
                v-model="standardAmount"
                :label="data.labels.standardAmount"
                :options="currentAmountOptions"
                name="standardAmount"
                @update:model-value="resetCustomAmount"
                @click.once="
                  useEventTracking(
                    'Payment Form - Step 3 - Standart amount chosen'
                  )
                "
              >
                <template #extra-item>
                  <div
                    class="payment-form__field-wrap payment-form__field-wrap--amount payment-form__field-wrap--mobile"
                  >
                    <BaseInputText
                      v-model.number="customAmount"
                      :error-message="customAmountErrorMessage"
                      :is-error="Boolean(customAmountErrorMessage)"
                      :placeholder="data.labels.anotherAmount"
                      :prefix="prefix"
                      is-big
                      @focus="resetStandardAmount"
                      @click.once="
                        useEventTracking(
                          'Payment Form - Step 3 - Custom amount chosen'
                        )
                      "
                    />
                  </div>
                </template>
              </BaseRadioGroup>
            </div>

            <div
              class="payment-form__field-wrap payment-form__field-wrap--amount payment-form__field-wrap--desktop"
            >
              <BaseInputText
                v-model.number="customAmount"
                :error-message="customAmountErrorMessage"
                :is-error="Boolean(customAmountErrorMessage)"
                :label="data.labels.customAmount"
                :placeholder="data.labels.anotherAmount"
                :prefix="prefix"
                is-big
                @focus="resetStandardAmount"
                @click.once="
                  useEventTracking(
                    'Payment Form - Step 3 - Custom amount chosen'
                  )
                "
              />
            </div>
          </fieldset>

          <fieldset class="payment-form__fields payment-form__fields--stripe">
            <Transition name="fade">
              <div
                class="payment-form__field-wrap payment-form__field-wrap--email"
              >
                <BaseInputText
                  v-model.trim="email"
                  :error-message="emailErrorMessage"
                  :is-error="Boolean(emailErrorMessage)"
                  :label="data.labels.email"
                  is-big
                  placeholder="Email"
                  type="email"
                  @click.once="
                    useEventTracking('Payment Form - Step 4 - Email chosen')
                  "
                />
              </div>
            </Transition>

            <Transition name="fade">
              <div class="payment-form__field-wrap">
                <StripeCard
                  ref="stripeCard"
                  :amount="resultAmount"
                  :currency="currency"
                  :email="email"
                  :is-custom-amount="isCustomAmount"
                  :is-english="props.isEnglish"
                  :is-subscription="isSubscription"
                  :label="data.labels.card"
                  @loaded="stripeIsLoaded = true"
                  @loading="loading = $event"
                  @successful-payment="showSuccessfulMessage = true"
                  @click.once="
                    useEventTracking('Payment Form - Step 5 - Card data chosen')
                  "
                />
              </div>
            </Transition>
          </fieldset>

          <button
            :class="{ 'payment-form__button--loading': loading }"
            :disabled="isButtonDisabled"
            class="payment-form__button"
            @click.prevent="handleSubmit"
          >
            <img
              v-if="loading"
              alt="loading"
              class="payment-form__button-spinner"
              src="~/assets/images/spinner.svg"
            />
            <span v-else>{{ buttonCaption }}</span>
          </button>

          <div class="payment-form__platform-logo">
            <BaseTooltip>
              <template #reference>
                <img :src="stripeLogo" alt="payment platform logo" />
              </template>
              <template #content>
                <div class="payment-form__platform-description">
                  {{ data.stripeDescription }}
                </div>
              </template>
            </BaseTooltip>
          </div>
          <NuxtTurnstile ref="turnstile" v-model="token" />
        </div>
      </Transition>
    </form>

    <span class="payment-form-wrap__offer">
      {{ data.labels.terms }}
      <BaseExternalLink :href="offerData.donation">{{
        data.labels.link
      }}</BaseExternalLink>
    </span>
  </span>
</template>

<style lang="postcss" scoped>
.payment-form {
  overflow: hidden;
  min-width: 728px;
  max-width: 728px;
  border-radius: 24px;
  box-shadow: -10px 10px 32px rgba(0, 0, 0, 8%);

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.2s ease-in-out;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }

  @media (-tablet-vp) {
    min-width: 648px;
    max-width: 648px;
  }

  &__inner {
    padding: 35px 40px 16px 40px;
    background: #fffafd;
  }

  &__fields {
    display: flex;
    align-items: center;
    margin: 0 0 24px 0;
    padding: 0;
    border: 0;
    gap: 24px;
  }

  &__field-wrap {
    &--card-type {
      display: inline-flex;
      align-items: flex-start;
      gap: 16px;
    }

    &--amount {
      width: 200px;
    }

    &--email {
      width: 240px;
    }

    &--mobile {
      display: none;
    }
  }

  &__error-message {
    font-size: 12px;
    line-height: 16px;
    position: absolute;
    right: auto;
    bottom: -16px;
    left: 0;
    width: 500px;
    color: var(--error-color);
  }

  &__button {
    font-size: 28px;
    font-weight: 700;
    line-height: 32px;
    position: relative;
    width: 100%;
    height: 60px;
    margin-bottom: 16px;
    cursor: pointer;
    transition: var(--default-transition) background-color;
    text-align: center;
    color: var(--black-color);
    border-radius: 16px;
    background-color: #74ffab;

    &:hover {
      background-color: #23ff7b;
    }

    &:active {
      background-color: #1ee46e;
    }

    &--loading {
      background-color: #1ee46e;
    }

    &:disabled {
      cursor: not-allowed;
      user-select: none;
      pointer-events: none;
      color: rgba(var(--black-color--rgb), 0.2);
    }
  }

  &__button-spinner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 80px;
    height: 80px;
    margin: auto;
  }

  &__platform-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
  }

  &__platform-description {
    width: 470px;
  }

  &__cp-link {
    font-size: 34px;
    line-height: 54px;
    display: block;
    padding: 8px 16px;
    transition: var(--default-transition) opacity;
    opacity: 1;
    color: var(--accent-color);
    border-radius: 12px;
    background-color: var(--control-background-color);

    &:hover {
      opacity: 0.6;
    }
  }

  @media (--small-vp) {
    width: 100%;
    min-width: 0;

    &__inner {
      padding: 16px;
    }

    &__fields {
      display: block;
      margin-bottom: 0;
    }

    &__field-wrap {
      margin-bottom: 20px;

      &--card-type {
        flex-wrap: wrap;
        gap: 8px;
      }

      &--mobile {
        display: block;
      }

      &--desktop {
        display: none;
      }

      &--amount {
        width: 100%;
        margin-bottom: 0;
      }

      &--email {
        width: 100%;
        margin-right: 0;
        margin-bottom: 24px;
      }
    }

    &__button {
      font-size: 22px;
      line-height: 27px;
      height: 72px;
      padding: 9px;
      text-align: center;
      border-radius: 12px;
    }

    &__button-spinner {
      width: 50px;
      height: 50px;
    }

    &__platform-description {
      width: 300px;
    }

    &__cp-link {
      font-size: 16px;
      line-height: 20px;
      padding: 11px 10px;
    }
  }

  @media (--x-small-vp) {
    &__field-wrap {
      &--email {
        width: 100%;
      }
    }
  }
}

.payment-form-wrap {
  position: relative;

  &__offer {
    font-size: 14px;
    line-height: 17px;
    position: absolute;
    right: 0;
    bottom: -30px;
    left: 0;
    margin: auto;
    text-align: center;
    color: rgba(var(--black-color--rgb), 40%);

    > br {
      display: none;
    }
  }

  @media (--tablet-vp) {
    &__offer {
      display: none;
    }
  }
}
</style>
