export const currencies = {
  rub: { name: 'rub', label: '₽' },
  usd: { name: 'usd', label: '$' },
  eur: { name: 'eur', label: '€' },
};

export const foreignCardAmountOptions = {
  usd: {
    onetime: [
      // The amountValues in cents!
      {
        amountValue: 500,
        label: '$5',
      },
      {
        amountValue: 1000,
        label: '$10',
      },
      {
        amountValue: 2000,
        label: '$20',
      },
      {
        amountValue: 5000,
        label: '$50',
      },
    ],
    recurring: [
      {
        amountValue: 500,
        // The priceIds are from stripe`s dashboard/products
        priceId: 'price_1MTmNdJp56eD21k8qAUqkVYW',
        label: '$5',
      },
      {
        amountValue: 1000,
        priceId: 'price_1MTmOCJp56eD21k8wyG5QYa3',
        label: '$10',
      },
      {
        amountValue: 2000,
        priceId: 'price_1MTmOjJp56eD21k8npF6sfil',
        label: '$20',
      },
      {
        amountValue: 5000,
        priceId: 'price_1MTmPhJp56eD21k86oOKumx2',
        label: '$50',
      },
    ],
  },
  eur: {
    onetime: [
      {
        // The amountValues in cents!
        amountValue: 500,
        label: '€5',
      },
      {
        amountValue: 1000,
        label: '€10',
      },
      {
        amountValue: 2000,
        label: '€20',
      },
      {
        amountValue: 5000,
        label: '€50',
      },
    ],
    recurring: [
      {
        amountValue: 500,
        // The priceIds are from stripe`s dashboard/products
        priceId: 'price_1MTmNdJp56eD21k872GqPDZm',
        label: '€5',
      },
      {
        amountValue: 1000,
        priceId: 'price_1MTmOCJp56eD21k8NyYZlzSG',
        label: '€10',
      },
      {
        amountValue: 2000,
        priceId: 'price_1MTmOjJp56eD21k8UGT11XXp',
        label: '€20',
      },
      {
        amountValue: 5000,
        priceId: 'price_1MTmPhJp56eD21k8ix9NhjzK',
        label: '€50',
      },
    ],
  },
};

export const russianCardAmountOptions = {
  onetime: [
    {
      amountValue: 5000,
      label: '5000₽',
    },
    {
      amountValue: 3000,
      label: '3000₽',
    },
    {
      amountValue: 1312,
      label: '1312₽',
    },
    {
      amountValue: 500,
      label: '500₽',
    },
  ],
  recurring: [
    {
      amountValue: 3000,
      label: '3000₽',
    },
    {
      amountValue: 1312,
      label: '1312₽',
    },
    {
      amountValue: 500,
      label: '500₽',
    },
    {
      amountValue: 300,
      label: '300₽',
    },
  ],
};
export const defaultData = {
  formErrors: {
    notNumber: 'Введите целое число',
    minValue: `Введите сумму не меньше`,
    required: 'Поле обязательно к заполнению',
    email: 'Неправильный адрес почты',
  },
  labels: {
    subscription: 'Как часто?',
    currency: 'Валюта',
    standardAmount: 'Выберите сумму',
    anotherAmount: 'Другая',
    customAmount: 'или введите свою',
    email: 'Ваша почта',
    card: 'и карта',
    cardNumber: 'Номер карты',
    buttonCaptionMonthly: 'Поддерживать ежемесячно',
    buttonCaptionOneTime: 'Поддержать разово',
    terms: `Поддерживая нас, вы соглашаетесь с`,
    link: 'условиями',
  },
  stripeDescription: `Stripe – это платежная система, которая отвечает за все финансовые операции, проводимые с её помощью. У нас нет никакой возможности сохранить данные банковской карты, т.к. Stripe шифрует их все на стороне вашего браузера`,
  successMessage: {
    text: `Спасибо, <br />что поддерживаете нас.`,
    extra: 'Другой мир возможен!',
  },
};

export const translatedData = {
  formErrors: {
    notNumber: 'Enter a whole number',
    minValue: 'Enter an amount no less than',
    required: 'This field is required',
    email: 'Invalid email address',
  },
  labels: {
    subscription: 'How often?',
    currency: 'Currency',
    standardAmount: 'Pick amount',
    anotherAmount: 'amount',
    customAmount: 'or enter your',
    email: 'Email',
    card: 'and card',
    cardNumber: 'card number',
    buttonCaptionMonthly: 'Support monthly',
    buttonCaptionOneTime: 'Support one time',
    terms: 'By supporting us, you agree to the',
    link: 'terms',
  },
  successMessage: {
    text: `Thank you, <br />we’re glad you’re supporting us.`,
    extra: 'Another world is possible!',
  },
  stripeDescription: `Stripe is a payment system that handles all financial transactions conducted through it. We do not have the ability to store any credit card data because Stripe encrypts it all on your browser side.`,
};

export const offerData = {
  donation:
    'https://drive.google.com/file/d/1DD6kVL6dii_Mmv1Vb_myNJVPNORI9sGH/view',
  email:
    'https://drive.google.com/file/d/1cIxW7LBzMSY4Qz31kzUTbaFJHCxk9e9_/view',
};
