<script setup>
import { computed } from '#imports';
import { isObject } from 'lodash-es';

const props = defineProps({
  modelValue: {
    type: [Object, String, Number, Boolean],
    required: true,
  },
  options: { type: Array, required: true },
  name: { type: String, required: true },
  label: { type: String, default: '' },
  valueFieldName: { type: String, default: 'value' },
  isSmall: { type: Boolean, default: false },
});

const emit = defineEmits(['update:modelValue']);

const value = computed({
  get() {
    return props.modelValue;
  },
  set(newValue) {
    emit('update:modelValue', newValue);
  },
});

function getValue(option) {
  if (isObject(props.modelValue)) {
    return option;
  }
  return option[props.valueFieldName];
}
</script>

<template>
  <div class="radio-group" :class="{ 'radio-group--small': isSmall }">
    <span v-if="label" class="radio-group__label-text">{{ label }}</span>
    <div
      class="radio-group__wrapper"
      :class="{
        'radio-group__wrapper--currency': name === 'currency',
        'radio-group__wrapper--amount': name === 'standardAmount',
      }"
    >
      <label
        v-for="(option, index) in options"
        :key="index"
        class="radio-group__label"
        :class="{ 'radio-group__label--disabled': option.disabled }"
        data-testid="element"
      >
        <input
          v-model="value"
          :value="getValue(option)"
          class="radio-group__control"
          type="radio"
          :name="name"
          :disabled="option.disabled"
          data-testid="control"
        />
        <span class="radio-group__custom-control">
          {{ option.label }}
        </span>
      </label>
      <slot name="extra-item" />
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.radio-group {
  &__wrapper {
    display: inline-flex;
    gap: 8px;
    align-items: flex-start;

    &__label {
      font-size: 28px;
      line-height: 32px;
      cursor: pointer;
      user-select: none;
    }

    &--currency {
      .radio-group__label {
        width: calc(50% - 6px);
        text-align: center;
      }

      .radio-group__custom-control {
        min-width: 56px;
        padding: 11px 0;
      }
    }

    &--amount {
      .radio-group__label {
        width: calc(25% - 6px);
        text-align: center;
      }

      .radio-group__custom-control {
        min-width: 77px;
        padding: 11px 0;
      }
    }
  }

  &__label-text {
    display: block;
    margin-bottom: 6px;
    position: relative;
    width: 100%;
    font-size: 18px;
    line-height: 22px;
    font-weight: 500;
    color: #938c92;
  }

  &__control {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    margin: 0;
    visibility: hidden;
    opacity: 0;
  }
  /* stylelint-disable no-descending-specificity */
  &__custom-control {
    display: block;
    padding: 11px 16px;
    border-radius: 16px;
    height: var(--control-height);
    color: var(--accent-color);
    background-color: var(--control-background-color);
    opacity: 1;
    font-size: 22px;
    transition: var(--default-transition) opacity;
    cursor: pointer;

    &:hover {
      opacity: 0.6;
    }
  }

  &__control:checked ~ &__custom-control {
    font-weight: 700;
    color: #fffafd;
    background-color: var(--accent-color);
    cursor: default;

    &:hover {
      opacity: 1;
    }
  }

  @media (--small-vp) {
    &__wrapper {
      gap: 8px;
      flex-wrap: wrap;
    }

    &__label {
      font-size: 22px;
      line-height: 27px;
    }

    &__label-text {
      margin-bottom: 4px;
      font-size: 12px;
      line-height: 15px;
    }

    &--small {
      .radio-group__custom-control {
        padding: 11px 10px;
      }

      .radio-group__label {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
}
</style>
