<script setup>
import { useRoute, useTrackEvent } from '#imports';

defineProps({
  href: {
    type: String,
    required: true,
  },
});

const currentRoute = useRoute();
function trackEvent(eventType, name, placement) {
  useTrackEvent(eventType, {
    props: {
      name,
      placement,
    },
  });
}
</script>

<template>
  <a
    :href="href"
    class="external-link"
    target="_blank"
    @click="
      trackEvent('Go to external link', `Go to ${href}`, currentRoute.path)
    "
  >
    <slot />
  </a>
</template>

<style lang="postcss" scoped>
.external-link {
  position: relative;
  padding: 0;
  border-bottom: 0.1em solid #f2e6f1;
  box-shadow: inset 0 -5px 0 #f2e6f1;
  transition: background 0.3s;
  color: var(--accent-color);
  line-height: 1;
  box-decoration-break: clone;
  border-radius: 0.1em;

  &:hover {
    background-color: #f2e6f1;
  }
}
</style>
