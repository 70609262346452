<script setup>
import {
  defaultData,
  translatedData,
  offerData,
} from '~/components/payment-form/constants';
import { useTrackEvent } from '#imports';

const props = defineProps({
  isEnglish: {
    type: Boolean,
  },
  description: {
    type: String,
    default: `Поддержите нас, чтобы мы могли продолжить работу. Оформите разовое
  или ежемесячное пожертвование.`,
  },
});

const content = {
  extraText: props.isEnglish
    ? 'We would also be happy to accept donations via Patreon and Crypto:'
    : 'Также мы принимаем пожертвования через:',
  crypto: props.isEnglish ? 'Cryptocurrency' : 'Криптовалюты',
};

const data = props.isEnglish ? translatedData : defaultData;

function trackEvent(eventType, name) {
  useTrackEvent(eventType, {
    props: {
      name,
      placement: window.location.pathname,
    },
  });
}

const handleClick = (name) => {
  trackEvent('click', name);
};
</script>

<template>
  <div
    :class="{
      'donation-block-payment--eng': isEnglish,
    }"
    class="donation-block-payment"
  >
    <div
      v-if="!isEnglish"
      class="donation-block-payment__description donation-block-payment__description--mobile"
    >
      {{ description }}
    </div>
    <div class="donation-block-payment__inner">
      <div class="donation-block-payment__inner">
        <PaymentForm :is-english="props.isEnglish" />
        <span class="donation-block-payment__offer">
          {{ data.labels.terms }}
          <BaseExternalLink :href="offerData.donation">{{
            data.labels.link
          }}</BaseExternalLink>
        </span>
        <div class="donation-block-payment__column">
          <div v-if="!isEnglish" class="donation-block-payment__description">
            {{ description }}
          </div>
          <div class="donation-block-payment__extra">
            {{ content.extraText }}
            <div class="donation-block-payment__links">
              <a
                class="donation-block-payment__links-item"
                href="https://www.patreon.com/doxajournal"
                target="_blank"
                @click="handleClick('Click on Patreon')"
              >
                Patreon
                <img alt="arrow" src="~/assets/images/arrow.svg" />
              </a>
              <DonationBlockCryptoWallets>
                <template #reference>
                  <span
                    class="donation-block-payment__links-item donation-block-payment__links-item--crypto"
                    >{{ content.crypto }}
                    <img alt="question" src="~/assets/images/question.svg" />
                  </span>
                </template>
              </DonationBlockCryptoWallets>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.donation-block-payment {
  &__offer {
    font-size: 14px;
    display: none;
    line-height: 17px;
    margin: auto;
    text-align: center;
    color: rgba(var(--black-color--rgb), 40%);

    > br {
      display: none;
    }
  }

  @media (--tablet-vp) {
    &__offer {
      display: block;
    }
  }

  @media (--tablet-vp) {
    &__offer {
      bottom: -100px;

      > br {
        display: block;
      }
    }
  }

  &__inner {
    display: flex;
    width: 100%;
    gap: 28px;
  }

  &__column {
    font-size: var(--body-font-size);
    line-height: var(--body-line-height);
    display: flex;
    flex-direction: column;
    max-width: 430px;
    padding: 30px 0 60px;
  }

  &__description {
    margin-bottom: auto;
    color: #fff6fb;

    &--mobile {
      display: none;
    }
  }

  &__extra {
    max-width: 300px;
    color: #fff6fb;
  }

  &__links {
    display: flex;
    flex-wrap: wrap;
    width: 300px;
    margin-top: 16px;
    color: #0c1a11;
    gap: 8px;
  }

  &__links-item {
    font-size: var(--h6-font-size);
    font-weight: 700;
    line-height: var(--h6-line-height);
    display: flex;
    align-items: center;
    padding: 4px 6px;
    transition: var(--default-transition) opacity;
    border-radius: 8px;
    background-color: #fffafd;
    gap: 6px;

    &:hover {
      opacity: 0.8;
    }

    &--crypto {
      cursor: default;
      background-color: #74ffab;
    }
  }

  &--eng {
    & .donation-block-payment__column {
      justify-content: flex-end;
      padding: 0;

      @media (--tablet-vp) {
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: none;
        padding: 0;
      }
    }

    & .donation-block-payment__extra {
      font-size: 18px;
      line-height: 28px;
      width: 200px;
      color: var(--black-color);

      @media (--tablet-vp) {
        font-size: 14px;
        line-height: 18px;
        width: 100%;
      }
    }

    & .donation-block-payment__links {
      width: 200px;
      @media (--tablet-vp) {
        width: 100%;
      }
    }
  }

  &__crypto-tooltip {
    width: 472px;
  }

  @media (--tablet-vp) {
    &__description {
      display: none;

      &--mobile {
        display: block;
        margin-bottom: 8px;
      }
    }

    &__container {
      padding: 0 12px;
    }

    &__inner {
      align-items: center;
      flex-direction: column;
      padding-bottom: 0;
      gap: 16px;
    }

    &__column {
      align-self: center;
      padding: 0;
    }

    &__extra {
      font-size: 14px;
      line-height: 18px;
      justify-content: center;
      width: max-content;
      max-width: none;
      text-align: center;

      & > br {
        display: none;
      }
    }

    &__links {
      justify-content: center;
      width: 300px;
      margin: 8px auto 0;
    }
  }
}
</style>
