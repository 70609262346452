import { ref, watch } from '#imports';
import {
  translatedData,
  defaultData,
} from '~/components/payment-form/constants';

export function useValidateForm({ email, customAmount, isEnglish }) {
  const emailErrorMessage = ref('');
  const customAmountErrorMessage = ref('');
  const minValue = '3';
  const labels = isEnglish ? translatedData : defaultData;

  function validateCustomAmount(value) {
    if (value === undefined) {
      customAmountErrorMessage.value = '';
      return;
    }
    if (!Number.isInteger(value)) {
      customAmountErrorMessage.value = labels.formErrors.notNumber;
      return;
    }
    if (value < minValue) {
      customAmountErrorMessage.value = `${labels.formErrors.minValue} ${minValue}`;
      return;
    }
    customAmountErrorMessage.value = '';
  }

  function validateEmail(value) {
    if (!value) {
      emailErrorMessage.value = labels.formErrors.required;
      return;
    }

    if (!/^([\w+.-])+@(([\dA-Za-z-])+\.)+([\dA-Za-z]{2,4})+$/.test(value)) {
      emailErrorMessage.value = labels.formErrors.email;
      return;
    }
    emailErrorMessage.value = '';
  }

  watch(email, (value) => validateEmail(value));
  watch(customAmount, (value) => validateCustomAmount(value));

  return {
    emailErrorMessage,
    customAmountErrorMessage,
  };
}
