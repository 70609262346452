<script setup>
import { useTrackEvent } from '#imports';

const wallets = [
  { name: 'Bitcoin', code: 'bc1qcp8839vknwqffvnd6r0t0rqtke9ajzt2ca2pap' },
  { name: 'Ethereum', code: '0x09DF23795efeCb7405D3F5c9dB0DbDA4a6e8b874' },
  { name: 'USDT', code: '0x09DF23795efeCb7405D3F5c9dB0DbDA4a6e8b874' },
  { name: 'USDC', code: '0x09DF23795efeCb7405D3F5c9dB0DbDA4a6e8b874' },
  { name: 'BNB', code: 'bnb1r5x4axqnxzdgtpgk6dnheqswqxcdhen2gha59n' },
  {
    name: 'Monero',
    code: '43LoESNYYQs5e9pkFZGsECTXvEaZLV2EFRb3nCGDCkzEH8aiUsJZAxD2gDNzJPp82JJTLsvB7GTptYCgQjnxXiFS9Cvh64F',
  },
];
function trackEvent(eventType, name) {
  useTrackEvent(eventType, {
    props: {
      name,
      placement: window.location.pathname,
    },
  });
}

const handleClick = (name) => {
  trackEvent('click', name);
};
</script>

<template>
  <ul class="crypto-wallets-list">
    <li v-for="item in wallets" :key="item.code" class="crypto-wallets__item">
      <div class="crypto-wallets-list__name">{{ item.name }}:</div>
      <BaseCopyToClipboard
        :value="item.code"
        @click="handleClick('Copy criptowallet')"
      >
        <span class="crypto-wallets-list__key">{{ item.code }}</span>
        <img
          class="crypto-wallets-list__copy-icon"
          src="~/assets/images/copy.png"
          alt="copy to clipboard"
        />
      </BaseCopyToClipboard>
    </li>
  </ul>
</template>

<style lang="postcss" scoped>
.crypto-wallets-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 472px;
  font-size: 16px;
  line-height: 22px;
  word-wrap: break-word;
  text-align: left;

  &__name {
    font-weight: 700;
  }

  &__key {
    font-size: 16px;
    line-height: 22px;
  }

  &__copy-icon {
    display: inline-block;
    margin-left: 4px;
    width: 14px;
    height: 14px;
  }

  @media (--small-vp) {
    max-width: none;
  }
}
</style>
