<script setup>
import { copyToClipboard } from '~/helpers/copy-to-clipboard';
import { toast } from 'vue3-toastify';
import { config } from '~/services/toasts/config';

const props = defineProps({
  value: {
    type: [Number, String],
    default: '',
  },
});

function handleCopy() {
  const isCopied = copyToClipboard(props.value);
  if (isCopied) toast.success('Скопировано в буфер обмена', config);
}
</script>

<template>
  <button
    class="copy-to-clipboard"
    title="Скопировать в буфер обмена"
    @click="handleCopy"
  >
    <slot />
  </button>
</template>

<style scoped>
.copy-to-clipboard {
  max-width: 100%;
  background: none;
  box-shadow: none;
  appearance: none;
  border: none;
  padding: 0;
  margin: 0;
  text-align: start;
  cursor: pointer;
  transition: var(--default-transition) opacity;

  &:hover {
    opacity: 0.7;
  }
}
</style>
