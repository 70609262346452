import { computed } from '#imports';
import { foreignCardAmountOptions } from './constants';

export function useAmountOptions({ currency, isSubscription }) {
  const onetimePaymentOptions = computed(
    () => foreignCardAmountOptions[currency.value.name].onetime
  );

  const recurringPaymentOptions = computed(
    () => foreignCardAmountOptions[currency.value.name].recurring
  );

  return computed(() =>
    isSubscription.value
      ? recurringPaymentOptions.value
      : onetimePaymentOptions.value
  );
}
